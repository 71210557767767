import React from "react";
import { Calendar, Event } from "./types/event";
import { User } from "./types/user";

export type Notification = {
  title: string;
  message: string;
  level: "error" | "info" | "success";
};

export type ViewMode = 'calendar' | 'list';

export interface IAppContext {
  showEventModal: boolean;
  setShowEventModal: (input: boolean) => void;
  showLoginModal: boolean;
  setShowLoginModal: (input: boolean) => void;
  showNewCalendarModal: boolean;
  setShowNewCalendarModal: (input: boolean) => void;
  showSelectCalendarModal: boolean;
  setShowSelectCalendarModal: (input: boolean) => void;
  addEvent: (event: Event) => Promise<void>;
  saveEvent: (event: Event) => Promise<void>;
  removeEvent: (event: Event) => void;
  selectedDate: Date;
  setSelectedDate: (input: Date) => void;
  selectedEvent?: Event;
  setSelectedEvent: (input?: Event) => void;
  loginViaGoogle: () => Promise<User | null>;
  logOut:  () => Promise<void>;
  calendar?: Calendar;
  calendars?: Array<Calendar>;
  saveCalendar: (input: Calendar) => Promise<void>;
  removeCalendar: (input: Calendar) => Promise<void>;
  user?: User;
  calendarId?: string;
  viewMode: ViewMode;
  setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
  setCalendar: React.Dispatch<React.SetStateAction<Calendar | undefined>>
}

export default React.createContext<IAppContext>({
  setShowEventModal: () => {},
  showEventModal: false,
  setShowLoginModal: () => {},
  showLoginModal: false,
  setShowNewCalendarModal: () => {},
  showNewCalendarModal: false,
  showSelectCalendarModal: false,
  setShowSelectCalendarModal: () => {},
  addEvent: () => Promise.resolve(),
  saveEvent: () => Promise.resolve(),
  selectedDate: new Date(),
  setSelectedDate: () => {},
  setSelectedEvent: () => {},
  removeEvent: () => {},
  loginViaGoogle: () => Promise.resolve(null),
  logOut: () => Promise.resolve(),
  saveCalendar: () => Promise.resolve(),
  removeCalendar: () => Promise.resolve(),
  viewMode: 'calendar',
  setViewMode: () => {},
  setCalendar: () => {},
});
