import dayjs from "dayjs";
import React from "react";
import { FunctionComponent, useContext } from "react";
import AppContext from "../../AppContext";
import { Event } from "../../types/event";
import { eventColorStyles, getTop } from "../../utils/cellUtils";

interface EventCardProps {
  event: Event;
  row: number;
}

const getHeight = (startTime: string, endTime: string) => {
  return (dayjs(endTime).diff(dayjs(startTime), "minute") / 30) * 12 * 4;
};

const EventCard: FunctionComponent<EventCardProps> = ({ event, row }) => {
  const appContext = useContext(AppContext);
  const onCardClicked: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    appContext.setSelectedEvent(event);
    appContext.setShowEventModal(true);
  };

  return (
    <div
      style={{
        height: getHeight(event.from, event.to),
        top: getTop(event.from, Math.round((row / 2) * 100) / 100),
      }}
      className="absolute w-full p-1 z-10"
    >
      <div
        style={{
          backgroundImage: event.image ? `url(${event.image})` : undefined,
        }}
        data-eventid={event.id}
        onClick={onCardClicked}
        tabIndex={-1}
        role="button"
        className={`bg-white bg-no-repeat bg-center	bg-cover h-full rounded-md shadow-md text-left focus:outline-none focus:ring focus:ring-violet-300 relative ${event.color
          ? eventColorStyles[event.color]
          : "bg-pink-100 border-pink-300"
          }`}
      >
        <div
          className={`sticky top-32 rounded-t-md lg:top-48 px-1 pt-1  ${event.image && "bg-white/[0.7]"
            }`}
        >
          <div className="text-black/[0.6] text-xs flex justify-between">
            <span>
              {dayjs(event.from).format("HH:mm")} -{" "}
              {dayjs(event.to).format("HH:mm")}
            </span>
            <div>
              {event.distance && <span>{event.distance}</span>}
            </div>
          </div>
          <h4 className={`font-semibold flex items-top lg:text-xs md:text-xs`}>
            {event.name}
          </h4>
          <p className="">
            {event.icon && (
              <span className="material-icons bg-indigo-500 text-white px-1 rounded-full !text-base">
                {event.icon}
              </span>
            )}
            {event.link && (
              <a
                className="ml-1 material-icons bg-indigo-500 px-1 text-white rounded-full !text-base"
                href={event.link}
              >
                link
              </a>
            )}
            {!!event.list?.length && (
              <span className="ml-1 material-icons bg-indigo-500 px-1 text-white rounded-full !text-base">
                list
              </span>
            )}
            {event.googlemap && (
              <a
                className="ml-1 material-icons bg-indigo-500 px-1 text-white rounded-full !text-base"
                href={event.googlemap}
                target="_blank"
                rel="noreferrer"
              >
                map
              </a>
            )}
            {event.location && (
              <>
                <span className="ml-1 material-icons bg-indigo-500 text-white px-1 rounded-full !text-base">
                  place
                </span>
                <span className="text-xs px-1">{event.location}</span>
              </>
            )}
          </p>
        </div>

      </div>
    </div>
  );
};

export default React.memo(EventCard);
