import { FunctionComponent, useContext } from "react";

import AppContext from "../../AppContext";

import { baseButtonStyles } from "../../styleUtils";
import Popup from "../Popup";

interface LoginModalProps { }

const LoginModal: FunctionComponent<LoginModalProps> = () => {
  const appContext = useContext(AppContext);
  const closeModalClicked = () => {
    appContext.setShowLoginModal(false);
  };
  const onLoginGoogleClicked = () => {
    return appContext.loginViaGoogle();
  }

  return (
    <Popup disableClose title="Login" show={appContext.showLoginModal} closeModalClicked={closeModalClicked}>
      <div className="border-t pt-6 mt-6">
        <h2 className="text-3xl font-semibold">Welcome to WKalender</h2>
        <p className="mt-4 text-gray-600">WKalender is an app for week plans for groups.</p>
        <div className="mt-8">
          <button
            type="submit"
            className={baseButtonStyles}
            onClick={onLoginGoogleClicked}
          >
            Login with Google
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default LoginModal;
