// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDRJd1PDhCQ62L7As00EdE7bWl5uC0Kv-k",
  authDomain: "shareschedule-d145b.firebaseapp.com",
  projectId: "shareschedule-d145b",
  storageBucket: "shareschedule-d145b.appspot.com",
  messagingSenderId: "234310789792",
  appId: "1:234310789792:web:d1dd04e5b823c6728077c5",
  measurementId: "G-EKNGVQM760"
};

export const initFireBase = () => {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  return app;
}