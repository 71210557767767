import {
  getAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  updateProfile,
  User,
  UserCredential,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { User as AppUser } from "../types/user";

const provider = new GoogleAuthProvider();

export const loginViaGoogle = () => {
  const auth = getAuth();

  return signInWithPopup(auth, provider);    
}

export const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: `${window.location.protocol}//${window.location.host}/authenticate`,
  // This must be true.
  handleCodeInApp: true,
};

export const sendMagicLink = (email: string) => {
  const auth = getAuth();

  return sendSignInLinkToEmail(auth, email, actionCodeSettings).then(() => {
    window.localStorage.setItem("emailForSignIn", email);
  });
};

export const authenticateByMagicLink = (
  input: string
): Promise<UserCredential | null> => {
  const auth = getAuth();

  if (isSignInWithEmailLink(auth, window.location.href)) {
    let email = window.localStorage.getItem("emailForSignIn");
    if (!email) {
      email = window.prompt("Please provide your email for confirmation");
    }
    // The client SDK will parse the code from the link for you.
    return signInWithEmailLink(auth, email!, window.location.href);
  }
  return Promise.resolve(null);
};


export const onAuthChanged = (): Promise<User | null> => {
  const auth = getAuth();
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      resolve(user)
    });
  })
}

export const saveUser = async (u: AppUser) => {
  const auth = getAuth();
  if (auth.currentUser) {
    await updateProfile(auth.currentUser, {
      displayName: u.name,
    });
  }
};
export const logOut = () => {
  const auth = getAuth();
  return auth.signOut();
}