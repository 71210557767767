import { FunctionComponent, useState } from "react";
import { EventIcon } from "../../types/event";
import { icons } from "../../utils/cellUtils";

interface IconPickerProps {
  icon?: EventIcon;
  onChange: (icon?: EventIcon) => void;
}

const IconPicker: FunctionComponent<IconPickerProps> = ({ icon, onChange }) => {
  const [showPicker, setShowPicker] = useState(false);
  const onPickerClicked = () => {
    setShowPicker(!showPicker);
  };
  const onIconPickerClicked = (icon?: EventIcon) => () => {
    onChange(icon);
    setShowPicker(false);
  }
  return (
    <div className="relative">
      <button
        type="button"
        onClick={onPickerClicked}
        className={`${icon && 'material-icons'} text-black bg-gray-200 border-blue-300 border p-2 text-white rounded-md`}
      >
        {icon || 'Select'}
      </button>
      <div
        className={`transition-all origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-lg ${!showPicker
          ? "opacity-0 translate-y-full invisible"
          : "block ease-out opacity-1"
          }`}
      >
        <div className="rounded-md bg-gray-200 shadow-xs px-4 py-3">
          <div className="flex flex-wrap -mx-2">
            {icons.map((icon) => (
              <div key={icon} className="px-2">
                <button
                  onClick={onIconPickerClicked(icon as EventIcon)}
                  type="button"
                  className={`material-icons w-8 h-8 inline-flex rounded-md cursor-pointer focus:outline-none focus:shadow-outline`}
                >
                  {icon}
                </button>
              </div>
            ))}
            <div className="px-2">
              <button type="button" onClick={onIconPickerClicked()} className="h-8 inline-flex items-center text-black bg-white border-blue-300 border p-2 text-white rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IconPicker;
