import React, { useEffect } from "react";

import WeekView from "../components/Home/WeekView";
import EventModal from "../components/Home/EventModal";
import LoginModal from "../components/Home/LoginModal";
import NewCalendarModal from "../components/Home/NewCalendarModal";
import SelectCalendarModal from "../components/Home/SelectCalendarModal";
import withAppContext from "../hoc/withAppContext";

function Home() {

  useEffect(() => {
    requestAnimationFrame(() => {
      const currentTime = document
        .getElementById("current-time");
      if (currentTime)
        currentTime.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center'
        });
    });
  }, []);

  return (
    <>
      <div className="App h-full relative lg:p-4 lg:pt-0 overflow-y-scroll">
        <WeekView />
      </div>
      <LoginModal key="login" />
      <EventModal key="event" />
      <NewCalendarModal key="new-calendar" />
      <SelectCalendarModal key="select-calendar" />
    </>
  );
}

export default withAppContext(Home);
