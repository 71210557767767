import dayjs from "dayjs";
import {
  FunctionComponent,
  useContext,
} from "react";
import AppContext from "../../AppContext";
import { Event } from "../../types/event";
import { eventColorStyles } from "../../utils/cellUtils";

interface EventCardSimpleProps {
  event: Event;
}

const EventCardSimple: FunctionComponent<EventCardSimpleProps> = ({ event }) => {
  const appContext = useContext(AppContext);
  const onCardClicked: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    appContext.setSelectedEvent(event);
    appContext.setShowEventModal(true);
  };

  return (
    <div
      className="w-full p-1 z-10"
    >
      <div
        data-eventid={event.id}
        onClick={onCardClicked}
        tabIndex={-1}
        role="button"
        className={`border h-full rounded-md shadow-md text-left p-2 focus:outline-none focus:ring focus:ring-violet-300 relative ${event.color
          ? eventColorStyles[event.color]
          : "bg-pink-100 border-pink-300"
          }`}
      >
        <div className="sticky top-32 lg:top-40">
          <div className="text-black/[0.6] text-sm flex justify-between">
            <span>
              {dayjs(event.from).format("HH:mm")} -{" "}
              {dayjs(event.to).format("HH:mm")}
            </span>
            {event.distance && <span>{event.distance}</span>}
          </div>
          <h4
            className={`font-bold flex items-top ${event.name.length > 20 && "text-xs"
              }`}
          >
            {event.name}
          </h4>
          <p className="">
            {event.icon && (
              <span className="material-icons bg-indigo-500 text-white px-1 rounded-full !text-base">
                {event.icon}
              </span>
            )}
            {event.link && (
              <a
                className="ml-1 material-icons bg-indigo-500 px-1 text-white rounded-full !text-base"
                href={event.link}
              >
                link
              </a>
            )}
            {event.googlemap && (
              <a
                className="ml-1 material-icons bg-indigo-500 px-1 text-white rounded-full !text-base"
                href={event.googlemap}
                target="_blank"
                rel="noreferrer"
              >
                map
              </a>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EventCardSimple;
