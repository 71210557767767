import dayjs from "dayjs";
import { EventColor } from "../types/event";

export const getTop = (startTime: string, endTime: number) => {
  const startDateTime = dayjs(startTime);
  const pivotTime = dayjs(startDateTime.format("YYYY-MM-DD")).add(
    endTime,
    "hour"
  );

  return (startDateTime.diff(pivotTime, "minute") / 30) * 16 * 4;
};

export const colors: {
  [Property in EventColor]: string;
} = {
  pink: "bg-pink-400",
  gray: "bg-gray-400",
  blue: "bg-blue-400",
  lime: "bg-lime-400",
  yellow: "bg-yellow-400",
};

export const eventColorStyles: {
  [Property in EventColor]: string;
} = {
  pink: "bg-pink-100 border-pink-300",
  gray: "bg-gray-100 border-gray-300",
  blue: "bg-blue-100 border-blue-300",
  lime: "bg-lime-100 border-lime-300",
  yellow: "bg-yellow-100 border-yellow-300",
};

export const icons = ['shopping_cart', 'local_dining', 'flight_takeoff', 'flight_land', 'rocket_launch', 'rowing', 'whatsapp', 'weekend', 'task', 'airport_shuttle','tour', 'museum', 'star_rate']