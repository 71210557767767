import { FunctionComponent, useEffect } from "react";
import * as L from 'leaflet';

import CalendarMap from '../components/CalendarMap';

interface MapProps {

}

const Map: FunctionComponent<MapProps> = () => {
  useEffect(() => {
    /*
    var map = L.map('map').setView([36.4399366, 28.2081378], 17);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
    var marker = L.marker([36.4399366, 28.2081378]).addTo(map);


    return () => {
      map.off();
      map.remove();
    }
    */
  }, [])
  return (
    <>
      <CalendarMap id="map1" focusPoint={{ point: [36.4399366, 28.2081378] }} zoom={17} />
    </>
  );
}

export default Map;