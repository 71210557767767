import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppContext, { ViewMode } from "../AppContext";
import { User } from "../types/user";
import * as AuthHandler from '../lib/auth';
import * as CalendarRepository from '../lib/calendarRepository';
import { Calendar, Event } from "../types/event";
import { getCalendarLink } from "../utils/dateUtils";
import dayjs from "dayjs";
import { Unsubscribe } from "firebase/firestore";


const withAppContext =
  (Component: React.FunctionComponent) =>
    (props: any): React.ReactElement => {
      const [showEventModal, setShowEventModal] = useState(false);
      const [showLoginModal, setShowLoginModal] = useState(false);
      const [showNewCalendarModal, setShowNewCalendarModal] = useState(false);
      const [showSelectCalendarModal, setShowSelectCalendarModal] = useState(false);
      const [calendars, setCalendars] = useState<Array<Calendar>>([]);
      const [viewMode, setViewMode] = useState<ViewMode>('calendar');

      const params = useParams<{ id: string, date: string }>();
      const [selectedDate, setSelectedDate] = useState<Date>(params.date ? new Date(params.date) : new Date());
      const [user, setUser] = useState<User>();
      const [selectedEvent, setSelectedEvent] = useState<Event>();
      const [isAuthReady, setIsAuthReady] = useState(false);
      const [calendar, setCalendar] = useState<Calendar>();
      const now = dayjs().format("YYYY-MM-DD HH:mm");
      const addEvent = async (ev: Event) => {

        if (!calendar) return;
        calendar.events.push(ev);
        calendar.events = [...calendar.events];
        await CalendarRepository.saveCalendar(calendar);
      };
      const saveEvent = async (ev: Event) => {
        if (!calendar) return;

        const index = calendar.events.findIndex((e) => e.id === ev.id);
        calendar.events.splice(index, 1, ev);
        calendar.lastUpdatedBy = user?.email!;
        calendar.lastUpdatedDate = now;
        setCalendar({ ...calendar })
        await CalendarRepository.saveCalendar(calendar);
      };
      const removeEvent = (ev: Event) => {
        if (!calendar) return;

        const index = calendar.events.findIndex((e) => e.id === ev.id);
        calendar.lastUpdatedBy = user?.email!;
        calendar.lastUpdatedDate = now;
        calendar.events.splice(index, 1);
        setCalendar({ ...calendar })
        CalendarRepository.saveCalendar(calendar);
      };
      useEffect(() => {
        let unSub: Unsubscribe;
        if (user && !params.id) {
          CalendarRepository.loadCalendars(user).then(calendars => {
            setCalendars(calendars);
            setShowNewCalendarModal(!calendars.length);
            if (calendars.length > 1)
              setShowSelectCalendarModal(!!calendars.length);
            else if (calendars.length === 1) {
              const calendar = calendars[0];
              setCalendar(calendar);
              navigate(getCalendarLink(calendar));
            }
          })
        }
        if (user && params.id) {
          unSub= CalendarRepository.subscribeCalendar(params.id, cal => {
            if(cal.sharedBy.includes(user.email)) {
              setCalendar(cal);
            } else {
              navigate('/403');
            }
          })
          CalendarRepository.loadCalendars(user).then(calendars => {
            setCalendars(calendars);
          })
        }
        return () => {
          if(unSub) unSub();
        }
      }, [params.id, user])
      useEffect(() => {
        if (params.date) {
          setSelectedDate(new Date(params.date));
        }
      }, [params.date])

      const loginViaGoogle = () => {
        return AuthHandler.loginViaGoogle().then(async (result) => {
          if (result) {
            const user: User = {
              email: result.user.email!,
              name: result.user.displayName,
              providerId: result.providerId,
              id: result.user.email || result.user.uid,

            };

            setUser(user);
            return user;
          }
          return null;
        })
      }

      useEffect(() => {
        AuthHandler.onAuthChanged().then(async (user) => {
          if (user) {
            const u: User = {
              email: user.email!,
              name: user.displayName,
              id: user.email || user.uid,
            };

            setUser(u);
          }
          setIsAuthReady(true);
        });
      }, []);

      useEffect(() => {
        if (isAuthReady) {

          setShowLoginModal(!Boolean(user));
        }
      }, [isAuthReady, user])

      const saveCalendar = async (input: Calendar) => {
        await CalendarRepository.saveCalendar(input);
        const index = calendars.findIndex(c => c.id ===  input.id);
        calendars.splice(index, 1, input);
        setCalendars(calendars);
        setCalendar(input)
      }
      const navigate = useNavigate();
      const removeCalendar = async (input: Calendar) => {
        await CalendarRepository.removeCalendar(input.id);
        setCalendar(undefined);
        navigate('/');
      }

      const logOut = () => {
        return AuthHandler.logOut().then(() => {
          setUser(undefined);
          navigate('/')
        })
      }
      
      return (
        <AppContext.Provider
          value={{
            calendars,
            showEventModal,
            setShowEventModal,
            showLoginModal,
            setShowLoginModal,
            showNewCalendarModal,
            setShowNewCalendarModal,
            showSelectCalendarModal,
            setShowSelectCalendarModal,
            addEvent,
            saveEvent,
            removeEvent,
            selectedDate,
            setSelectedDate,
            selectedEvent,
            setSelectedEvent,
            loginViaGoogle,
            logOut,
            user,
            calendar,
            saveCalendar,
            removeCalendar,
            calendarId: params.id,
            viewMode,
            setViewMode,
            setCalendar,
          }}
        >
          {!isAuthReady ? (
            <div className="bg-white text-center flex-col flex items-center h-screen justify-center">
              <h1 className="text-2xl"> Welcome to WKalender </h1>
              <p>The app is initializing...</p>
            </div>
          ) : (
            <Component {...props} />
          )}
        </AppContext.Provider>
      );
    };

export default withAppContext;
