import { useFormik } from "formik";
import { FunctionComponent, useContext } from "react";
import * as Yup from "yup";
import AppContext from "../../AppContext";

import { baseButtonStyles } from "../../styleUtils";

interface EmailListProps {
  items: Array<string>;
  onChange: (items: Array<string>) => void;
}

const EmailSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const EmailList: FunctionComponent<EmailListProps> = ({ items, onChange }) => {
  const appContext = useContext(AppContext);
  const formik = useFormik({
    initialValues: { email: "" },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: EmailSchema,
    onSubmit: async (values) => {
    },
  });
  const onAddClicked = () => {
    formik.validateForm(formik.values);
    if (formik.isValid) {
      if (!items.includes(formik.values.email)) {
        items.push(formik.values.email);
        onChange(items);
      }
      formik.resetForm();
    }
  };
  const onRemoveClicked = (index: number) => () => {
    items.splice(index, 1);
    onChange(items);
  };
  return (
    <ul>
      {items.map((item, index) => (
        <li
          className="bg-gray-200 px-4 pr-0 py-2 flex justify-between mt-2"
          key={item}
        >
          {item}
          {item !== appContext.user?.email && <div>
            <button type="button" onClick={onRemoveClicked(index)} className="material-icons px-2">
              delete_outline
            </button>
          </div>}
        </li>
      ))}
      <li className="mt-4 flex">
        <input
          className="border w-full px-4 py-2"
          type="text"
          placeholder="Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <button
          onClick={onAddClicked}
          type="button"
          className={`${baseButtonStyles} ml-2 bg-indigo-500`}
        >
          Add
        </button>
      </li>
    </ul>
  );
};

export default EmailList;
