import { FunctionComponent } from "react";
import { baseInputFieldStyles } from "../styleUtils";

interface TextAreaFieldProps extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>
{
  
}
 
const TextAreaField: FunctionComponent<TextAreaFieldProps> = (props) => {
  return (<textarea className={baseInputFieldStyles} {...props} />);
}
 
export default TextAreaField;