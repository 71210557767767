import { useFormik } from "formik";
import { FunctionComponent } from "react";
import * as Yup from "yup";

import { baseButtonStyles } from "../../styleUtils";

import { ListItem, ListItemType } from "../../types/event";
import TextAreaField from "../TextAreaField";
import TextField from "../TextField";

interface ListItemFormProps {
  data: ListItem;
  onAddClicked: (input: ListItem) => void;
  onCancelClicked: () => void;
}

const ListSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  content: Yup.string(),
  url: Yup.string().url("Content should be an url"),
});

const eatingLabels = [
  "$$$$",
  "$$",
  "$",
  "Excellent",
  "Good",
  "Normal",
  "Bad",  
  "View",
  "PriceWorthy",
  "FamilyFriendly",
  "Vegan",
  "Seafood",
  "Traditional"
];
const placeLabels = ["MustSee", "History", "FamilyFriendly"];
const LabelsMapping = new Map<ListItemType, Array<string>>();
LabelsMapping.set("item", []);
LabelsMapping.set("place", placeLabels);
LabelsMapping.set("eating", eatingLabels);

const ListForm: FunctionComponent<ListItemFormProps> = ({
  data,
  onAddClicked,
  onCancelClicked,
}) => {
  const formik = useFormik<ListItem>({
    initialValues: data,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ListSchema,
    onSubmit: async (values) => {},
  });

  const addClicked = () => {    
    onAddClicked(formik.values);
  };

  const cancelClicked = () => {
    onCancelClicked();
  };

  return (
    <div className="mt-2 border rounded-md p-2">
      <TextField
        type="text"
        placeholder="List item title"
        name="title"
        value={formik.values.title}
        onChange={formik.handleChange}
      />
      <TextField
        type="text"
        placeholder="Url"
        name="url"
        value={formik.values.url || ""}
        onChange={formik.handleChange}
      />
      <TextAreaField
        placeholder="Content"
        name="content"
        value={formik.values.content}
        onChange={formik.handleChange}
      />
      {!!LabelsMapping.get(data.type)?.length && (
        <div className="flex mt-2 py-2 overflow-x-scroll scrollbar">
          {(LabelsMapping.get(data.type) || []).map((label) => (
            <div key={label}>
              <input
                className="peer hidden"
                type="checkbox"
                id={`ckb_${label}`}
                name="labels"
                onChange={formik.handleChange}
                checked={(formik.values.labels || []).includes(label)}
                value={label}
              />
              <label
                htmlFor={`ckb_${label}`}
                className="border text-xs px-2 py-1 mr-1 rounded-full bg-gray-100 peer-checked:bg-indigo-500 peer-checked:text-white"
                key={label}
              >
                {label}
              </label>
            </div>
          ))}
        </div>
      )}
      <button
        onClick={addClicked}
        type="button"
        className={`${baseButtonStyles} mt-2 bg-indigo-500 px-8`}
      >
        Save
      </button>
      <button
        onClick={cancelClicked}
        type="button"
        className={`${baseButtonStyles} mt-2 ml-2 bg-red-500 px-8`}
      >
        Cancel
      </button>
    </div>
  );
};

export { ListForm };
