import dayjs from "dayjs";
import { FunctionComponent, useContext } from "react";
import AppContext from "../../AppContext";
import { eventColorStyles } from "../../utils/cellUtils";
import { isEventForToday } from "../../utils/eventUtils";
import CalendarMap, { MapPoint, MapPresentation, parseGoogleMapLink } from "../CalendarMap";
import { renderMd } from "../Editor";

interface EventReadOnlyProps { }

const EventReadOnly: FunctionComponent<EventReadOnlyProps> = () => {
  const appContext = useContext(AppContext);
  const event = appContext.selectedEvent!;
  let focusPoint: MapPresentation | undefined;
  const events = appContext.calendar?.events || [];

  const eventsInDay = events.filter((e) =>
    isEventForToday(e, dayjs(event.from))
  );
  const locations = eventsInDay
    .map((e) => {
      const location = (e.googlemap ? parseGoogleMapLink(e.googlemap) : null);
      if (location && location.focusPoint) {
        location.focusPoint.place = e.name;
        location.focusPoint.note = e.note;
        if(e.googlemap === event.googlemap) {
          focusPoint = location;
        }
      }      
      return location;
    })
    .reduce((acc, loc) => {
      return loc && loc.focusPoint ? [...acc, loc.focusPoint] : acc;
    }, [] as Array<MapPoint>);
  return (
    <div
      className={`border rounded-md mt-4 text-left p-2 relative ${event.color
          ? eventColorStyles[event.color]
          : "bg-pink-100 border-pink-300"
        }`}
    >
      <div className="text-black/[0.6] text-sm flex justify-between">
        <span>
          {dayjs(event.from).format("HH:mm")} -{" "}
          {dayjs(event.to).format("HH:mm")}
        </span>
        {event.distance && <span>{event.distance}</span>}
      </div>
      <h4 className={`font-bold flex items-top text-2xl`}>{event.name}</h4>

      <p className="">
        {event.icon && (
          <span className="material-icons bg-indigo-500 text-white px-1 rounded-full !text-base">
            {event.icon}
          </span>
        )}
        {event.link && (
          <a
            className="ml-1 material-icons bg-indigo-500 px-1 text-white rounded-full !text-base"
            href={event.link}
            target="_blank"
            rel="noreferrer"
          >
            link
          </a>
        )}
        {event.googlemap && (
          <a
            className="ml-1 material-icons bg-indigo-500 px-1 text-white rounded-full !text-base"
            href={event.googlemap}
            target="_blank"
            rel="noreferrer"
          >
            map
          </a>
        )}
        {!!event.list?.length && (
          <span className="ml-1 material-icons bg-indigo-500 px-1 text-white rounded-full !text-base">
            list
          </span>
        )}
        {event.location && (
          <>
            <a
              target="_blank"
              href={`https://www.google.com/maps/search/?api=1&query=${event.location}`}
              rel="noreferrer"
              className="ml-1 material-icons bg-indigo-500 text-white px-1 rounded-full !text-base"
            >
              place
            </a>
            <a
              target="_blank"
              href={`https://www.google.com/maps/search/?api=1&query=${event.location}`}
              rel="noreferrer"
              className="text-xs px-1"
            >
              {event.location}
            </a>
          </>
        )}
      </p>
      <section className="overflow-y-auto">
        {event.note && (
          <div className="mt-4 px-4 bg-white overflow-hidden rounded-md">
            <h4 className="mt-2 font-semibold">Note</h4>
            <p
              dangerouslySetInnerHTML={{ __html: renderMd(event.note) }}
              className="break-words mt-2 text-gray-600 text-sm pb-2"
            ></p>
          </div>
        )}
        {!!event.list?.length && (
          <div className="mt-4 px-4 bg-white overflow-hidden rounded-md">
            <h4 className="mt-2 font-semibold">List</h4>
            <ul className="mt-2">
              {(event.list || []).map((item, index) => (
                <li
                  className="justify-between mb-2 text-sm rounded-md overflow-hidden border-t py-2"
                  key={item.id}
                >
                  <h4 className="font-semibold">
                    {item.url ? (
                      <a
                        target="_blank"
                        className="block text-blue-500"
                        href={item.url}
                        rel="noreferrer"
                      >
                        {item.title}
                      </a>
                    ) : (
                      item.title
                    )}
                  </h4>
                  <p className="whitespace-pre-wrap text-xs">{item.content}</p>
                  {!!item.labels?.length && (
                    <div className="mt-1 flex flex-wrap">
                      {(item.labels || []).map((label) => (
                        <span
                          key={label}
                          className="px-2 py-1 border text-xs rounded-full bg-gray-200 mr-1"
                        >
                          {label}
                        </span>
                      ))}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </section>
      {focusPoint && focusPoint.focusPoint && (
        <CalendarMap
          zoom={focusPoint.zoom}
          focusPoint={focusPoint.focusPoint}
          id="map"
          markers={locations}
        />
      )}
    </div>
  );
};

export default EventReadOnly;
