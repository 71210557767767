import { FunctionComponent, useEffect } from "react";
import { isEqual } from "lodash/fp";
import * as L from "leaflet";

export type MapPoint = {
  place?: string;
  point: [number, number];
  note?: string;
};

export type MapPresentation = {
  focusPoint: MapPoint | null;
  zoom: number;
};

interface MapProps {
  focusPoint: MapPoint;
  markers?: Array<MapPoint>;
  zoom: number;
  id: string;
}

// https://www.google.com/maps/place/Rhodos+Acropolis/@36.4359945,28.1954803,15z/data=!4m5!3m4!1s0x1495618b76b18c47:0x98970e3bf47683c0!8m2!3d36.4399367!4d28.2103266
export const parseGoogleMapLink = (input: string): MapPresentation | null => {
  const regxPlace = new RegExp("^https?://[^/]+/maps/place/([^/]+)/@([^/]+)");
  const regx = new RegExp("^https?://[^/]+/maps/@([^/]+)");
  if (regxPlace.test(input)) {
    const [, place, position] = regxPlace.exec(input) || [];
    const [x, y, zoom] = (position || "").split(",");
    return {
      focusPoint: { place: decodeURIComponent(place), point: [+x, +y] },
      zoom: +zoom.replace("z", ""),
    };
  }
  if (regx.test(input)) {
    const [, position] = regx.exec(input) || [];
    const [x, y, zoom] = (position || "").split(",");
    return { focusPoint: { point: [+x, +y] }, zoom: +zoom.replace("z", "") };
  }
  return null;
};

type Feature = {
  type: string;
  id: string | number;
  properties: {
    name?: string;
    note?: string;
    isFocusPoint: boolean;
  };
  geometry: {
    coordinates: Array<number>;
    type: string;
  };
};

const CalendarMap: FunctionComponent<MapProps> = ({
  focusPoint,
  id,
  zoom,
  markers,
}) => {
  useEffect(() => {
    var map = L.map(id).setView(focusPoint.point, 1);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    const geojsonFeature: Array<Feature> = (markers || []).map((m, id) => {
      return {
        type: "Feature",
        id,
        properties: {
          name: m.place,
          note: m.note,
          isFocusPoint: isEqual(m.point, focusPoint.point),
        },
        geometry: {
          type: "Point",
          coordinates: [...m.point].reverse(),
        },
      };
    });

    function onEachFeature(
      feature: Feature,
      layer: { bindPopup: (arg0: string) => void }
    ) {
      const popupContent = `<div><h4>${feature.properties.name}</h4><p>${
        feature.properties.note || ""
      }</p></div>`;

      layer.bindPopup(popupContent);
    }

    // @ts-ignore
    const myLayer = L.geoJSON(geojsonFeature, {
      onEachFeature: onEachFeature,
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng, {
          radius: 8,
          fillColor: feature.properties.isFocusPoint ? "#22c55e" : "#f97316",
          color: "#000",
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8,
        });
      },
    }).addTo(map);
    const bounds = myLayer.getBounds();
    map.fitBounds(bounds.pad(0.5));

    return () => {
      map.off();
      map.remove();
    };
  }, [JSON.stringify(focusPoint), id, JSON.stringify(markers), zoom]);
  return <div className="w-full h-96" id={id}></div>;
};

export default CalendarMap;
