import { FunctionComponent } from "react";

interface NoPermissionPageProps {}

const NoPermissionPage: FunctionComponent<NoPermissionPageProps> = () => {
  return (
    <div className="text-center px-4">
      <h1 className="text-2xl mt-24">You do not have permissions to the calendar</h1>
      <p className="text-gray-600 mt-2">If you get this link from another then ask that person to add you to the email list of the calendar</p>
      <div className="mt-4 text-blue-500">
        <a href="/">Back to Home Page</a>
      </div>
    </div>
  );
};

export default NoPermissionPage;
