import { FunctionComponent } from "react";

interface NotFoundPageProps {}

const NotFoundPage: FunctionComponent<NotFoundPageProps> = () => {
  return (
    <div className="text-center px-4">
      <h1 className="text-2xl mt-24">Something Not Found :(</h1>
      <p className="text-gray-600 mt-2">You are looking an unreal thing</p>
      <div className="mt-4 text-blue-500">
        <a href="/">Back to Home Page</a>
      </div>
    </div>
  );
};

export default NotFoundPage;
