import dayjs from "dayjs";
import { useFormik } from "formik";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import * as Yup from "yup";

import AppContext from "../../AppContext";
import DateField from "../DateField";
import FormField from "../FormField";
import { baseButtonStyles } from "../../styleUtils";
import TextField from "../TextField";
import {
  Event,
  EventColor,
  EventIcon,
  ListItem,
} from "../../types/event";
import { generateId } from "../../idUtils";
import { useNavigate } from "react-router-dom";
import Popup from "../Popup";
import ColorPicker from "./ColorPicker";
import IconPicker from "./IconPicker";
import EventReadOnly from "./EventReadOnly";
import ListContent from "./ListContent";
import Editor from "../Editor";
import SlideButton from "../SlideButton";

interface EventFormProps {
  closeModalClicked: () => void;
  copyEventClicked?: (ev: Event) => void;
  cancelClicked: () => void;
  defaultEventData?: Event;
}

const EventSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  googlemap: Yup.string().url("Not url format"),
});

const calculateDiffInMinutes = (from: string, to: string) =>
  dayjs(to).diff(dayjs(from), "minute");

const options = [
  { label: "1h", value: 1 },
  { label: "2h", value: 2 },
  { label: "5h", value: 5 },
  { label: "+30m", value: "+30 minutes" },
  { label: "-30m", value: "-30 minutes" },
];

const EventForm: FunctionComponent<EventFormProps> = ({
  closeModalClicked,
  defaultEventData,
  cancelClicked,
}) => {
  const appContext = useContext(AppContext);
  const sp = new URLSearchParams(window.location.search);
  const refTime = sp.get("refTime");

  const today = dayjs(refTime || appContext.selectedDate || undefined);
  const defaultEvent: Event = {
    name: "",
    from: today.format("YYYY-MM-DDTHH:mm"),
    to: today.add(30, "minute").format("YYYY-MM-DDTHH:mm"),
    location: "",
    id: generateId(),
    note: "",
    distance: "",
    link: "",
    image: "",
  };
  const effectiveEvent =
    appContext.selectedEvent || defaultEventData || defaultEvent;
  const isEditMode = !!appContext.selectedEvent;
  const [duration, setDuration] = useState<number>(
    calculateDiffInMinutes(effectiveEvent.from!, effectiveEvent.to!)
  );

  const formik = useFormik<Event>({
    initialValues: effectiveEvent,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: EventSchema,
    onSubmit: (values) => {
      formik.validateForm();
      if (formik.isValid) {
        if (!isEditMode) appContext.addEvent(values);
        else appContext.saveEvent(values);
        closeModalClicked();
        formik.resetForm();
        setDuration(30);
        appContext.setSelectedEvent(undefined);
      }
    },
  });

  const channgeDuration = (input: number) => {
    setDuration(input);
    formik.setFieldValue(
      "to",
      dayjs(formik.values.from).add(input, "minute").format("YYYY-MM-DDTHH:mm")
    );
  };
  const onDurationChanged: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    channgeDuration(+e.target.value);
  };
  const onToChanged: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    formik.handleChange(e);
    setDuration(calculateDiffInMinutes(formik.values.from, e.target.value));
  };

  const onFromChanged: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    formik.handleChange(e);
    formik.setFieldValue(
      "to",
      dayjs(e.target.value).add(duration, "minute").format("YYYY-MM-DDTHH:mm")
    );
  };

  const onCancelClicked = () => {
    formik.resetForm();
    setDuration(30);
    cancelClicked();
  };

  const onDurationOptionClicked = (option: string | number) => () => {
    switch (option) {
      case "+30 minutes":
        channgeDuration(duration + 30);
        break;
      case "-30 minutes":
        channgeDuration(duration - 30);
        break;
      default:
        if (typeof option === "number") {
          channgeDuration(option * 60);
        }
        break;
    }
  };

  const onColorChanged = (color: EventColor) => {
    formik.setFieldValue("color", color);
  };

  const onIconChanged = (icon?: EventIcon) => {
    formik.setFieldValue("icon", icon);
  };

  const onListChanged = (input: Array<ListItem>) => {
    formik.setFieldValue("list", input);
  };

  const onNoteChanged = ({ text }: { text: string }) => {
    formik.setFieldValue("note", text);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="mt-5">
        <FormField className="" id="name">
          <TextField
            value={formik.values.name}
            onChange={formik.handleChange}
            placeholder="Name"
            id="name"
          />
        </FormField>
        <div className="flex items-center mt-4">
          <div className="flex items-center">
            <span className="block text-sm text-gray-700 capitalize items-center mr-2">
              Icon:
            </span>
            <IconPicker icon={formik.values.icon} onChange={onIconChanged} />
          </div>
          <div className="flex items-center ml-1">
            <ColorPicker
              color={formik.values.color}
              onChange={onColorChanged}
            />
          </div>
        </div>

        <div className="mt-4">
          <label className="block text-sm text-gray-700 capitalize items-center">
            Duration(in minutes)
          </label>
          <FormField id="duration" className="flex" label="">
            <TextField
              value={duration.toString()}
              placeholder="Duration"
              id="duration"
              onChange={onDurationChanged}
              type="number"
              className="flex-1"
            />

            <ul className="flex mt-2 ml-2 rounded-md overflow-hidden">
              {options.map((opt, index) => (
                <li
                  className="py-1 bg-indigo-400 text-white text-sm"
                  key={opt.value}
                >
                  <button
                    onClick={onDurationOptionClicked(opt.value)}
                    className={`block h-full w-10 ${index < options.length - 1 && "border-r"
                      }`}
                    type="button"
                  >
                    {opt.label}
                  </button>
                </li>
              ))}
            </ul>
          </FormField>
        </div>

        <div className="grid lg:grid-cols-2 gap-4 mt-4">
          <FormField id="from-time" className="flex-1" label="From">
            <DateField
              value={formik.values.from}
              placeholder="From"
              id="from-time"
              onChange={onFromChanged}
              name="from"
            />
          </FormField>
          <FormField id="to-time" className="flex-1" label="To">
            <DateField
              placeholder="To"
              id="to-time"
              value={formik.values.to}
              onChange={onToChanged}
              name="to"
            />
          </FormField>
        </div>
        <FormField className="flex-1 mt-4 relative" label="Google map" id="googlemap">
          <TextField
            value={formik.values.googlemap}
            placeholder="googlemap"
            id="googlemap"
            onChange={formik.handleChange}
          />
          {formik.values.googlemap && (
            <a
              target="_blank"
              className="block text-blue-500 material-icons absolute z-10 -top-1 left-20"
              href={formik.values.googlemap}
              rel="noreferrer"
            >
              map
            </a>
          )}
        </FormField>
        <div className="grid lg:grid-cols-2 gap-4 mt-4">
          <FormField className="flex-1" label="Location" id="location">
            <TextField
              value={formik.values.location}
              onChange={formik.handleChange}
              placeholder="Location"
              id="location"
            />
          </FormField>
          <FormField className="flex-1" label="Distance" id="distance">
            <TextField
              value={formik.values.distance || ""}
              onChange={formik.handleChange}
              placeholder="Distance"
              id="distance"
            />
          </FormField>
        </div>
        <FormField className="mt-4 relative" label="Link" id="link">
          <TextField
            value={formik.values.link || ""}
            onChange={formik.handleChange}
            placeholder="Link"
            id="link"
          />
          {formik.values.link && (
            <a
              target="_blank"
              className="block pl-2 text-blue-500 material-icons absolute z-10 -top-1 left-6"
              href={formik.values.link}
              rel="noreferrer"
            >
              open_in_new
            </a>
          )}
        </FormField>
        <FormField className="mt-4 relative" label="Image" id="image">
          <TextField
            value={formik.values.image || ""}
            onChange={formik.handleChange}
            placeholder="Link to image"
            id="image"
          />
        </FormField>

        <FormField className="mt-4" label="Note" id="note">
          <Editor value={formik.values.note || ""} onChange={onNoteChanged} />
        </FormField>
        <FormField className="mt-4" label="List" id="links">
          <ListContent items={formik.values.list} onChange={onListChanged} />
        </FormField>
        <div className="flex justify-end mt-6 bg-white">
          <button type="submit" className={baseButtonStyles}>
            {isEditMode ? "Save" : "Create"}
          </button>
          {isEditMode && (
            <button
              onClick={onCancelClicked}
              type="button"
              className={`${baseButtonStyles} ml-2 bg-red-500 hover:bg-red-600`}
            >
              Cancel
            </button>
          )}
        </div>
      </form>
    </>
  );
};

interface EventModalProps { }

type ViewMode = "readonly" | "edit" | "create";

const EventModal: FunctionComponent<EventModalProps> = () => {
  const appContext = useContext(AppContext);
  const [defaultEventData, setDefaultEvent] = useState<Event>();
  const [viewMode, setViewMode] = useState<ViewMode>("readonly");
  const navigate = useNavigate();
  const closeModalClicked = () => {
    appContext.setShowEventModal(false);
    setDefaultEvent(undefined);
    navigate(window.location.pathname);
    setViewMode(appContext.selectedEvent ? "readonly" : "create");
  };
  const copyEventClicked = (ev: Event) => {
    setDefaultEvent(ev);
  };
  const title =
    viewMode === "edit"
      ? "Edit event"
      : viewMode === "readonly"
        ? "Event"
        : "New event";
  useEffect(() => {
    if (appContext.selectedEvent?.id) setViewMode("readonly");
    else setViewMode("create");
  }, [appContext.selectedEvent?.id]);
  const onEditModeClicked: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setViewMode("edit");
  };
  const onCopyClicked: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    appContext.setSelectedEvent(undefined);
    const copied: Event = { ...appContext.selectedEvent! };
    copied.id = generateId();
    copyEventClicked(copied);
  };
  const onDeleteClicked: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm('Are you sure to delete this event?')) {
      appContext.removeEvent(appContext.selectedEvent!);
      closeModalClicked();
    }
  };
  const onCancelClicked = () => {
    setViewMode("readonly");
  };
  const moveEvent = (minutes: number) => () => {
    const ev = appContext.selectedEvent;

    if (ev) {
      ev.from = dayjs(ev.from)
        .add(minutes, "minute")
        .format("YYYY-MM-DDTHH:mm");
      ev.to = dayjs(ev.to).add(minutes, "minute").format("YYYY-MM-DDTHH:mm");
      appContext.saveEvent(ev);
    }
  };
  const moveEventDay = (day: number) => () => {
    const ev = appContext.selectedEvent;

    if (ev) {
      ev.from = dayjs(ev.from).add(day, "day").format("YYYY-MM-DDTHH:mm");
      ev.to = dayjs(ev.to).add(day, "day").format("YYYY-MM-DDTHH:mm");
      appContext.saveEvent(ev);
    }
  };
  return (
    <Popup
      show={appContext.showEventModal}
      closeModalClicked={closeModalClicked}
      title={title}
      renderTopRight={() => (
        <>
          {appContext.selectedEvent && (
            <button
              className="material-icons mr-2"
              type="button"
              onClick={onDeleteClicked}
            >
              delete
            </button>
          )}
          {appContext.selectedEvent && (
            <SlideButton
              id="ckb_move"
              label="open_with"
              labelClassName="material-icons mr-2 cursor-pointer"
            >
              <button
                onClick={moveEvent(-30)}
                type="button"
                className="p-2 w-32 border-b  text-left"
              >
                Move up
              </button>
              <button
                onClick={moveEvent(30)}
                type="button"
                className="p-2 w-32 border-b  text-left"
              >
                Move down
              </button>
              <button
                onClick={moveEventDay(1)}
                type="button"
                className="p-2 w-32 border-b  text-left"
              >
                Next day
              </button>
              <button
                onClick={moveEventDay(-1)}
                type="button"
                className="p-2 w-32 border-b  text-left"
              >
                Previous day
              </button>
            </SlideButton>
          )}

          {appContext.selectedEvent && (
            <button
              className="material-icons mr-2"
              type="button"
              onClick={onCopyClicked}
            >
              content_copy
            </button>
          )}
          {viewMode === "readonly" && appContext.selectedEvent && (
            <button
              className="material-icons mr-2"
              type="button"
              onClick={onEditModeClicked}
            >
              edit
            </button>
          )}
        </>
      )}
    >
      {viewMode !== "readonly" && (
        <EventForm
          closeModalClicked={closeModalClicked}
          defaultEventData={defaultEventData}
          copyEventClicked={copyEventClicked}
          cancelClicked={onCancelClicked}
          key={`${window.location.pathname}%${window.location.search}_${appContext.selectedEvent?.id}`}
        />
      )}
      {viewMode === "readonly" && appContext.selectedEvent && <EventReadOnly />}
    </Popup>
  );
};

export default EventModal;
