import { FunctionComponent } from "react";

interface HourCellProps
  extends React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
  > {
  children?: React.ReactNode;
  className?: string;
}

const HourCell: FunctionComponent<HourCellProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      role="button"
      className={`flex-1 text-center border-l relative ${className}`}
    >
      {children}
    </div>
  );
};

export default HourCell;