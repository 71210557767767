import { FunctionComponent, useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../AppContext";
import { getCalendarLink } from "../../utils/dateUtils";
import Popup from "../Popup";

interface SelectCalendarProps { }

const SelectCalendarModal: FunctionComponent<SelectCalendarProps> = () => {
  const appContext = useContext(AppContext);
  const closeModalClicked = () => {
    appContext.setShowSelectCalendarModal(false);
  };

  const onEditClicked = () => {
    closeModalClicked();
    appContext.setShowNewCalendarModal(true);
  };

  const onRemoveClicked = () => {
    if (appContext.calendar) appContext.removeCalendar(appContext.calendar);
  };
  const onCreateNewClicked = () => {
    closeModalClicked();
    appContext.setCalendar(undefined);
    appContext.setShowNewCalendarModal(true);
  }
  return (
    <Popup
      show={appContext.showSelectCalendarModal}
      closeModalClicked={closeModalClicked}
      title="Select Calendar"
    >
      <ul className="w-full">
        {(appContext.calendars || []).map((calendar) => (
          <li
            className={`mt-1 bg-gray-200 px-4 pr-0 py-2 ${appContext.calendarId === calendar.id && "text-indigo-500"
              }`}
            key={calendar.id}
          >
            <div className="flex justify-between">
              <Link
                className="flex-1 block"
                onClick={closeModalClicked}
                to={getCalendarLink(calendar)}
              >
                {calendar.name}
              </Link>
              {appContext.calendarId === calendar.id && (
                <div>
                  <button
                    type="button"
                    onClick={onEditClicked}
                    className="material-icons px-2"
                  >
                    edit
                  </button>
                  <button
                    type="button"
                    onClick={onRemoveClicked}
                    className="material-icons px-2"
                  >
                    delete_outline
                  </button>
                </div>
              )}
            </div>
            {appContext.calendarId === calendar.id && (
              <div className="text-gray-600">
                {calendar.note}
              </div>
            )}
          </li>
        ))}
      </ul>
      <div className="text-center">
        <button onClick={onCreateNewClicked} type="button" className="py-2 px-8 mt-4 rounded-lg bg-indigo-500 text-white">New calendar</button>
      </div>
    </Popup>
  );
};

export default SelectCalendarModal;
