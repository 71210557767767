import { FunctionComponent, useRef, useState } from "react";
import { ListItem, ListItemType } from "../../types/event";
import { generateId } from "../../idUtils";
import { ListForm } from "./ListItemForm";
import { renderMd } from "../Editor";

interface ListContentProps {
  items?: Array<ListItem>;
  onChange: (input: Array<ListItem>) => void;
}

const types: Array<{ label: string; value: ListItemType }> = [
  { value: "place", label: "New Place" },
  { value: "eating", label: "New Eating" },
];

const getNewFormData = (type: ListItemType): ListItem => ({
  title: "",
  content: "",
  url: "",
  type,
  id: generateId(),
  labels: [],
});

const ListContent: FunctionComponent<ListContentProps> = ({
  items,
  onChange,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [type, setType] = useState<ListItemType>("item");
  const [editItem, setEditItem] = useState<ListItem>(getNewFormData(type));
  const ckbRef = useRef<HTMLInputElement>(null);

  const onRemoveClicked = (index: number) => () => {
    if (items) {
      items.splice(index, 1);
      onChange(items);
    }
  };
  const onAddClicked = (input: ListItem) => {
    const index = (items || []).findIndex((i) => i.id === input.id);
    if (!items || index === -1) {
      const arr = ([] as Array<ListItem>).concat(items || [], input);
      onChange(arr);
    } else {
      items.splice(index, 1, input);
      onChange(items);
    }
    setShowForm(false);
  };
  const onEditClicked = (link: ListItem) => () => {
    setEditItem(link);
    setType(link.type);
    setShowForm(true);
  };
  const toggleForm = () => {
    setShowForm(!showForm);
  };
  const selectType = (newType: ListItemType) => () => {
    setType(newType);
    setEditItem(getNewFormData(newType));
    toggleForm();
    ckbRef.current!.checked = false;
  };
  return (
    <div>
      <ul>
        {(items || []).map((item, index) => (
          <li
            className="bg-gray-200 px-4 pr-0 py-2 justify-between mt-2 text-sm rounded-md flex overflow-hidden"
            key={item.id}
          >
            <div className="flex-1 overflow-hidden">
              {item.url ? (
                <a className="text-blue-500" href={item.url}>
                  <h4 className="font-semibold">{item.title}</h4>
                </a>
              ) : (
                <h4 className="font-semibold">{item.title}</h4>
              )}
              {item.content && (
                <p className="" dangerouslySetInnerHTML={{ __html: renderMd(item.content) }}></p>
              )}              
            </div>
            <button
              type="button"
              onClick={onEditClicked(item)}
              className="material-icons px-1"
            >
              edit
            </button>
            <button
              type="button"
              onClick={onRemoveClicked(index)}
              className="material-icons px-1"
            >
              delete_outline
            </button>
          </li>
        ))}
        <li className={`mt-2 block ${showForm ? "hidden" : "block"}`}>
          <div className="border rounded-md text-blue-500 border-gray-200 flex inline-flex relative">
            <button
              className="px-4 py-2 border-r"
              type="button"
              onClick={selectType("item")}
            >
              New Item
            </button>
            <label htmlFor="ckb_types" className="inline-flex items-center">
              <span className="material-icons">expand_more</span>
            </label>
            <input
              ref={ckbRef}
              type="checkbox"
              id="ckb_types"
              className="hidden peer"
            />
            <ul className="px-2 capitalize absolute top-12 rounded-md border bg-white right-0 w-full text-center shadow-md hidden peer-checked:block">
              {types.map((newType) => (
                <li
                  className="py-2 border-b last:border-b-0"
                  key={newType.value}
                >
                  <button onClick={selectType(newType.value)} type="button">
                    {newType.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </li>
        <li
          className={`transition-all block ${showForm ? "h-68" : "h-0 overflow-hidden"
            }`}
        >
          <ListForm
            data={editItem}
            onAddClicked={onAddClicked}
            onCancelClicked={toggleForm}
            key={editItem.id}
          />
        </li>
      </ul>
    </div>
  );
};

export default ListContent;
