import { FunctionComponent } from "react";
import {
  Route,
  Routes as ReactRoutes,
  BrowserRouter
} from "react-router-dom";

import Home from "./pages/Home";
import NotFoundPage from "./pages/NotFoundPage";
import NoPermissionPage from "./pages/NoPermissionPage";
import Map from "./pages/Map";

interface RoutesProps { }

const Routes: FunctionComponent<RoutesProps> = () => {
  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route path="/" element={<Home />} />
        <Route path="/map" element={<Map />} />
        <Route path="/calendar/:id" element={<Home />} />
        <Route path="/calendar/:id/:date" element={<Home />} />
        <Route path="/403" element={<NoPermissionPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </ReactRoutes>
    </BrowserRouter>
  );
};

export default Routes;
