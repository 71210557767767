import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { Calendar } from "../types/event";
import { User } from "../types/user";

const COLLECTION_NAME = 'Calendar';


export const loadCalendars = async (u: User): Promise<Array<Calendar>> => {
  const db = getFirestore();
  const colRef = collection(db, COLLECTION_NAME);
  const q = query(colRef, where("sharedBy", "array-contains", u.email));
  const querySnapshot = await getDocs(q);
  const res: Array<Calendar> = [];
  querySnapshot.forEach((doc) => {
    res.push(doc.data() as Calendar);
  });
  return res;
}

export const saveCalendar = async (calendar: Calendar) => {
  const db = getFirestore();
  await setDoc(doc(db, COLLECTION_NAME, calendar.id), calendar);
}

// need to check permission
export const loadCalendar = async (id: string): Promise<Calendar> => {
  const db = getFirestore();
  const docRef = doc(db, COLLECTION_NAME, id);
  const docSnap = await getDoc(docRef);
  return docSnap.data() as Calendar;
}


export const removeCalendar = async (id: string): Promise<void> => {
  const db = getFirestore();
  const docRef = doc(db, COLLECTION_NAME, id);
  return deleteDoc(docRef)
}

export const subscribeCalendar = (id: string, subFn: (input: Calendar) => void) => {
  const db = getFirestore();
  const docRef = doc(db, COLLECTION_NAME, id);
  const unsub = onSnapshot(docRef, (doc) => {
    subFn(doc.data() as Calendar);
  });
  return unsub;
}
