import { FunctionComponent } from "react";
import MdEditor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";
import "react-markdown-editor-lite/lib/index.css";

interface EditorProps {
  value: string;
  onChange: (
    data: {
      text: string;
      html: string;
    },
    event?: React.ChangeEvent<HTMLTextAreaElement> | undefined
  ) => void;
}

const mdParser = new MarkdownIt(/* Markdown-it options */);

export const renderMd = (mdText: string) =>  mdParser.render(mdText);

const Editor: FunctionComponent<EditorProps> = ({ value, onChange }) => {
  return (
    <MdEditor
      className="rounded-md mt-2 h-52"
      canView={{
        menu: true,
        md: true,
        html: false,
        fullScreen: true,
        hideMenu: false,
        both: false,
      }}
      plugins={[
        "header",
        "font-bold",
        "font-italic",
        "font-underline",
        "font-strikethrough",
        "list-unordered",
        "list-ordered",
        "link",
        "clear",
        "logger",
        "mode-toggle",
        "tab-insert",
      ]}
      view={{
        menu: true,
        md: true,
        html: false,
      }}
      
      onChange={onChange}
      value={value}
      renderHTML={renderMd}
    />
  );
};

export default Editor;
