import { FunctionComponent, useCallback, useEffect, useRef } from "react";

interface PopupProps {
  show: boolean;
  children: React.ReactNode;
  closeModalClicked: () => void;
  title: string;
  disableClose?: boolean;
  renderTopRight?: () => React.ReactNode;
}

export const isMobileBrowser = (): boolean =>
  Boolean(window.navigator.userAgent.match(/iPhone|Android/i));

const Popup: FunctionComponent<PopupProps> = ({
  show,
  children,
  closeModalClicked,
  title,
  disableClose,
  renderTopRight,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") closeModalClicked();
    };
    let scrollPosition = 0;
    window.document.body.addEventListener("keydown", onKeyDown);

    if (show && isMobileBrowser()) {
      scrollPosition = window.scrollY;
      window.document.body.classList.toggle("modal--show", true);
    }
    return () => {
      window.document.body.removeEventListener("keydown", onKeyDown);

      if (show && isMobileBrowser()) {
        window.document.body.classList.toggle("modal--show", false);
        window.scroll({ top: scrollPosition });
      }
    };
  }, [show]);

  const onBackgroundClicked: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      closeModalClicked();
    }
  };

  return (
    <div
      onClick={show ? onBackgroundClicked : undefined}
      className={`absolute bg-opacity-40 bg-gray-500 top-0 h-screen left-0 w-full ${
        !show ? "invisible pointer-events-none	z-10" : "z-20"
      }`}
    >
      <div
        className={`duration-500 transition-all w-full z-10 relative bottom-0 py-8 pb-0 lg:pb-8 ${show ? 'h-screen' : 'h-0'}`}
      >
        <div
          ref={show ? modalRef : undefined}
          className={`h-full flex flex-col duration-500 transition-all bg-white shadow-xl p-4 rounded-lg w-full max-w-xl m-auto ${
            !show ? "translate-y-full opacity-0" : "opacity-1"
          }`}
        >
          <div className="flex items-center justify-between sticky top-0 bg-white z-20 pt-4">
            <h1 className="text-xl font-medium text-gray-800">{title}</h1>
            <div>
              {renderTopRight && renderTopRight()}
              {!disableClose && (
                <button onClick={closeModalClicked}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
          <div className="flex-1 overflow-y-auto pb-24">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
