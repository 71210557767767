import { FunctionComponent } from "react";
import { baseInputFieldStyles } from "../styleUtils";

interface DateFieldProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
{
  
}
 
const DateField: FunctionComponent<DateFieldProps> = (props) => {
  return (<input className={baseInputFieldStyles} type="datetime-local" {...props} />);
}
 
export default DateField;