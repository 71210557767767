import dayjs from "dayjs";
import { FunctionComponent, useContext, useRef } from "react";
import isoWeek from "dayjs/plugin/isoWeek";
import { useNavigate } from "react-router-dom";
import AppContext from "../../AppContext";
import SlideButton from "../SlideButton";

dayjs.extend(isoWeek);

interface CalendarHeaderProps { }

const CalendarHeader: FunctionComponent<CalendarHeaderProps> = () => {
  const appContext = useContext(AppContext);

  const currentDate = dayjs(appContext.selectedDate);
  const today = dayjs();
  const navigate = useNavigate();
  const selectDate = (input: Date) => {
    appContext.setSelectedDate(input);
    navigate(
      `/calendar/${appContext.calendarId}/${dayjs(input).format("YYYY-MM-DD")}`
    );
  };
  const onPreviousWeekClicked = () => {
    selectDate(currentDate.subtract(7, "day").endOf("isoWeek").toDate());
  };
  const onNextWeekClicked = () => {
    selectDate(currentDate.add(7, "day").startOf("isoWeek").toDate());
  };
  const onTodayClicked = () => {
    selectDate(today.toDate());
  };
  const dateChanged: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    selectDate(new Date(e.target.value));
  };
  const onTitleClicked = () => {
    appContext.setShowSelectCalendarModal(true);
  };
  const onAddEventClicked = () => {
    appContext.setShowEventModal(true);
    appContext.setSelectedEvent(undefined);
  };
  const onListViewClicked = () => {
    appContext.setViewMode(
      appContext.viewMode === "calendar" ? "list" : "calendar"
    );
  };
  const onLoginClicked = () => {
    appContext.setShowLoginModal(true);
  };
  const isAuthReady = !!appContext.user;
  const isReadOnly = !isAuthReady || !appContext.calendar;
  const onLogoutClicked = () => {
    return appContext.logOut();
  }
  return (
    <div className="bg-slate-100 rounded-t-lg">
      <div className="flex justify-between items-center px-4 pt-2 lg:pt-4">
        {!isReadOnly && (
          <h1
            onClick={onTitleClicked}
            className="font-bold uppercase lg:text-xl flex items-center"
          >
            {appContext.calendar?.name || "Loading..."}
            <button className="inline-block material-icons">
              arrow_drop_down
            </button>
          </h1>
        )}
        {isReadOnly && (
          <h1 className="font-bold uppercase lg:text-xl flex items-center">
            Welcome to WKalender
          </h1>
        )}
        {isAuthReady && (
          <span className="text-sm lg:text-base text-right">
            Hello <span className="font-bold">{appContext.user?.name}</span>, <button onClick={onLogoutClicked} type="button">Logout</button>
          </span>
        )}
        {!isAuthReady && (
          <button onClick={onLoginClicked} className="text-sm lg:text-base">
            Login
          </button>
        )}
      </div>
      <div className="flex justify-between px-4 pb-2 lg:py-4 border-b">
        <div className="relative flex inline-flex">
          <label className="lg:text-xl inline-flex items-center">
            {currentDate.format("MMM")} {currentDate.year()}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 inline-block ml-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
          </label>
          <input
            className="datepicker-input"
            onChange={dateChanged}
            id="datePicker1"
            type="date"
            value={currentDate.format("YYYY-MM-DD")}
          />
        </div>
        <div className="flex lg:items-center flex-col lg:flex-row">
          {!today.isSame(appContext.selectedDate, "dates") && (
            <div className="hidden lg:block">
              <button
                onClick={onTodayClicked}
                className="py-2 border bg-white rounded-lg flex items-center text-black/[0.7] px-4"
              >
                Today
              </button>
            </div>
          )}
          <div className="hidden lg:block lg:ml-2 mt-2 lg:mt-0 py-2 border bg-white rounded-lg flex items-center text-black/[0.7]">
            <button
              onClick={onPreviousWeekClicked}
              type="button"
              className="px-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
            <span className="px-2">
              <label htmlFor="datePicker">
                {today.isSame(currentDate, "days")
                  ? "Today"
                  : currentDate.format("dddd, ")}
              </label>
              <input
                onChange={dateChanged}
                id="datePicker"
                type="date"
                disabled={!appContext.calendar}
                value={currentDate.format("YYYY-MM-DD")}
              />
            </span>
            <button onClick={onNextWeekClicked} className="px-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
          {appContext.calendarId && (
            <div className="hidden lg:block h-full py-2">
              <div className="border-r border-black/[0.7] mx-4 h-full"></div>
            </div>
          )}
          {appContext.calendarId && (
            <button
              onClick={onAddEventClicked}
              className="text-white bg-indigo-700 rounded-lg p-2 px-4 hidden lg:block"
            >
              Add event
            </button>
          )}
          <div className="relative inline-flex">
            <div className="inline-flex border rounded-lg lg:hidden">
              <button
                onClick={onPreviousWeekClicked}
                type="button"
                className="px-2 inline-block"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <div className="border-l" />
              <button onClick={onNextWeekClicked} className="px-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>
            <SlideButton
              id="ckb_eventDlg"
              slideClassName="right-0 shadow-lg border"
              className="lg:hidden"
              label={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                  />
                </svg>
              }
            >
              <button
                onClick={onAddEventClicked}
                className="py-2 px-4 w-full text-left w-32"
              >
                Add Event
              </button>
              <button
                onClick={onListViewClicked}
                className="py-2 px-4 border-t w-full text-left w-32"
              >
                {appContext.viewMode === "calendar"
                  ? "List View"
                  : "Week View"}
              </button>
              <button
                onClick={onTodayClicked}
                className="py-2 px-4 border-t w-full text-left w-32"
              >
                Today
              </button>
            </SlideButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalendarHeader;
