import { FunctionComponent, useState } from "react";
import { EventColor } from "../../types/event";
import { colors } from "../../utils/cellUtils";

interface ColorPickerProps {
  color?: EventColor;
  onChange: (color: EventColor) => void;
}

const ColorPicker: FunctionComponent<ColorPickerProps> = ({ color, onChange }) => {
  const [showPicker, setShowPicker] = useState(false);
  const onPickerClicked = () => {
    setShowPicker(!showPicker);
  };
  const onColorPicked = (color: EventColor) => () => {
    onChange(color);
    setShowPicker(false);
  }
  return (
    <div className="relative">
      <button
        type="button"
        onClick={onPickerClicked}
        className={`material-icons inline-block border-blue-300 border p-2 text-white rounded-md ${color ? colors[color] : colors.pink}`}
      >
        colorize
      </button>
      <div
        className={`transition-all origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-lg ${
          !showPicker
            ? "opacity-0 translate-y-full invisible"
            : "block ease-out opacity-1"
        }`}
      >
        <div className="rounded-md bg-gray-200 shadow-xs px-4 py-3">
          <div className="flex flex-wrap -mx-2">
            {Object.entries(colors).map(([key, bgColor]) => (
              <div key={key} className="px-2">
                <button
                  type="button"
                  onClick={onColorPicked(key as EventColor)}
                  className={`w-8 h-8 inline-flex rounded-md cursor-pointer focus:outline-none focus:shadow-outline ${bgColor}`}
                ></button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;
