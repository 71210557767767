import dayjs from "dayjs";
import { useFormik } from "formik";
import { FunctionComponent, useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import AppContext from "../../AppContext";
import { generateId } from "../../idUtils";

import { baseButtonStyles } from "../../styleUtils";
import { Calendar } from "../../types/event";
import FormField from "../FormField";
import Popup from "../Popup";
import TextAreaField from "../TextAreaField";
import TextField from "../TextField";
import EmailList from "./EmailList";

interface NewCalendarFormProps { }

const CalendarSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
});

const NewCalendarForm: FunctionComponent<NewCalendarFormProps> = () => {
  const appContext = useContext(AppContext);
  const closeModalClicked = () => {
    appContext.setShowNewCalendarModal(false);
  };
  const now = dayjs().format("YYYY-MM-DD HH:mm");
  const defaultCalendar: Calendar = {
    events: [],
    name: "",
    sharedBy: appContext.user?.email ? [appContext.user?.email] : [],
    note: "",
    id: generateId(),
    createdBy: appContext.user?.email!,
    createdDate: now,
    lastUpdatedBy: appContext.user?.email!,
    lastUpdatedDate: now,
    startDate: dayjs().format('YYYY-MM-DD'),
  };
  const isEditMode = !!appContext.calendar;
  const navigate = useNavigate();
  const formik = useFormik<Calendar>({
    initialValues: appContext.calendar || defaultCalendar,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: CalendarSchema,
    onSubmit: async (values) => {
      formik.validateForm();
      if (formik.isValid) {
        try {
          values.lastUpdatedDate = now;
          values.lastUpdatedBy = appContext.user?.email!;
          await appContext.saveCalendar(values);
          formik.resetForm();

          closeModalClicked();
          if (!isEditMode)
            navigate(`/calendar/${formik.values.id}`);
        } catch (error) {
          console.error(error);
        }
      }
    },
  });
  const onRemoveClicked = () => {
    if (appContext.calendar) appContext.removeCalendar(appContext.calendar);
  };

  const onEmailListChanged = (emails: Array<string>) => {
    formik.setFieldValue('sharedBy', emails);
  }

  return (

    <form onSubmit={formik.handleSubmit} className="mt-5">
      <FormField label="Name" id="name">
        <TextField
          value={formik.values.name}
          onChange={formik.handleChange}
          placeholder="Name"
          id="name"
        />
      </FormField>
      <FormField className="mt-4" label="Start date" id="startDate">
        <TextField
          value={formik.values.startDate}
          onChange={formik.handleChange}
          placeholder="Start date"
          type="date"
          id="startDate"
        />
      </FormField>

      <FormField className="mt-4" label="Share to" id="sharedBy">
        <EmailList onChange={onEmailListChanged} items={formik.values.sharedBy} />
      </FormField>
      <FormField className="mt-4" label="Note" id="note">
        <TextAreaField
          value={formik.values.note}
          onChange={formik.handleChange}
          placeholder="Note"
          id="note"
        />
      </FormField>
      <div className="flex justify-end mt-6">
        <button type="submit" className={baseButtonStyles}>
          {isEditMode ? "Save" : "Create"}
        </button>
        {isEditMode && (
          <button
            onClick={onRemoveClicked}
            type="button"
            className={`${baseButtonStyles} ml-2 bg-orange-500`}
          >
            Remove
          </button>
        )}
      </div>
    </form>

  );
};

interface NewCalendarModalProps { }

const NewCalendarModal: FunctionComponent<NewCalendarModalProps> = () => {
  const appContext = useContext(AppContext);
  const closeModalClicked = () => {
    appContext.setShowNewCalendarModal(false);
  };
  const isEditMode = !!appContext.calendar;
  const title = isEditMode ? "Edit Calendar" : "New Calendar"
  return (
    <Popup
      show={appContext.showNewCalendarModal}
      closeModalClicked={closeModalClicked}
      title={title}
    >
      {appContext.user && (
        <NewCalendarForm
          key={`${appContext.calendar?.id}_${appContext.calendar?.lastUpdatedDate}`}
        />
      )}
    </Popup>
  );
};

export default NewCalendarModal;
