import { FunctionComponent } from "react";

interface FormFieldProps {
  className?: string;
  label?: string;
  children?: React.ReactNode;
  id?: string;
}

const FormField: FunctionComponent<FormFieldProps> = ({ className, label, children, id }) => {
  return (
    <div className={className}>
      {label && <label className="block text-sm text-gray-700 capitalize" htmlFor={id}>
        {label}
      </label>}
      {children}
    </div>
  );
};

export default FormField;
