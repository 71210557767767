import {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import dayjs, { Dayjs } from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";

import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { Event } from "../../types/event";
import AppContext from "../../AppContext";
import EventCard from "./EventCard";
import { getTop } from "../../utils/cellUtils";
import { useNavigate } from "react-router-dom";
import CalendarHeader from "./CalendarHeader";
import EventCardSimple from "./EventCardSimple";
import HourCell from "./HourCell";
import { isEventForToday } from "../../utils/eventUtils";

dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);

interface WeekViewProps { }

interface WeekDayProps {
  children?: React.ReactNode;
  day: string;
  date: Dayjs;
  isCurrent?: boolean;
}

const WeekDay: FunctionComponent<WeekDayProps> = ({ day, date, isCurrent }) => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  const onDateClicked = () => {
    appContext.setSelectedDate(date.toDate());
    navigate(`/calendar/${appContext.calendarId}/${date.format("YYYY-MM-DD")}`);
  };
  return (
    <button
      onClick={onDateClicked}
      type="button"
      className={`flex-1 py-1 lg:py-4 text-center border-l lg:px-8 ${isCurrent ? "" : ""
        }`}
    >
      <span className="text-black/[0.6] block lg:inline-block">{day}</span>{" "}
      <span
        className={`${isCurrent
            ? "rounded-full bg-indigo-700 text-white inline-block w-8 h-8 text-center leading-8"
            : "font-bold w-8 h-8 inline-block leading-8"
          }`}
      >
        {date.date()}
      </span>
    </button>
  );
};

const getCellStyle = (rowIndex: number, colIndex?: number) => {
  if (colIndex === 0 && rowIndex === 0) return "";
  return rowIndex === 0 ? "bg-white border-white" : "";
};

const shouldRenderEvent = (
  event: Event,
  current: Dayjs,
  currentHour: number
) => {
  const startTime = dayjs(current.format("YYYY-MM-DD")).add(
    currentHour,
    "hour"
  );
  const endTime = startTime.add(0.5, "hour");
  const fromDate = dayjs(event.from);
  return fromDate.isSameOrAfter(startTime) && fromDate.isBefore(endTime);
};

const shouldRenderEventInWeek = (
  event: Event,
  startDay: Dayjs,
  endDay: Dayjs
) => {
  const fromDate = dayjs(event.from);
  return fromDate.isSameOrAfter(startDay) && fromDate.isSameOrBefore(endDay);
};

const shouldRenderCurrentTime = (
  now: Dayjs,
  weekDay: Dayjs,
  currentHour: number
) => {
  if (currentHour < 0) return false;
  const startTime = dayjs(weekDay.format("YYYY-MM-DD")).add(
    currentHour,
    "hour"
  );
  const endTime = startTime.add(0.5, "hour");

  return now.isSameOrAfter(startTime) && now.isBefore(endTime);
};

const WeekView: FunctionComponent<WeekViewProps> = () => {
  const appContext = useContext(AppContext);
  const [currentTime, setCurrentTime] = useState(dayjs());
  useEffect(() => {
    setTimeout(() => {
      setCurrentTime(dayjs());
    }, 1000 * 60);
  }, [currentTime]);

  const rows = Array.from(new Array(48).keys());
  const currentDate = dayjs(appContext.selectedDate);
  const startWeek = currentDate.startOf("isoWeek");
  const weekDays = Array.from(new Array(7).keys()).map((index) => {
    return startWeek.add(index, "day");
  });
  const events = appContext.calendar?.events || [];
  const navigate = useNavigate();
  const onHourClicked =
    (weekDay: Dayjs, hour: number): React.MouseEventHandler<HTMLDivElement> =>
      () => {
        appContext.setShowEventModal(true);
        appContext.setSelectedEvent(undefined);
        const refDate = dayjs(weekDay.format("YYYY-MM-DD")).add(hour, "hour");
        navigate(
          `${window.location.pathname}?refTime=${refDate.format(
            "YYYY-MM-DDTHH:mm"
          )}`
        );
      };
  useEffect(() => {
    const el = document.querySelector("#nav-container");
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
      { threshold: [1] }
    );

    observer.observe(el!);
    return () => observer.disconnect();
  }, []);
  const endWeek = startWeek.add(7, "day");
  const eventsInWeek = events.reduce((acc, e) => {
    if (!shouldRenderEventInWeek(e, startWeek, endWeek)) return acc;
    const key = dayjs(e.from).format("YYYY-MM-DD");
    const eventsInDay = acc.get(key) || [];
    eventsInDay.push(e);
    acc.set(key, eventsInDay);
    return acc;
  }, new Map<string, Array<Event>>());
  return (
    <div id="weekview" className="bg-slate-100 flex-1 lg:pt-4">
      <div
        id="nav-container"
        className="sticky top-0 z-20 bg-white rounded-t-lg border border-b-0"
      >
        <CalendarHeader />
        <div className="first-row flex border-b overflow-x-scroll shadow">
          <div className="text-center text-sm flex justify-center items-center min-w-fit">
            <div className="hidden lg:block lg:w-12 px-1 font-bold">
              <span className="hidden lg:block">Week</span>
              <span className="block lg:hidden">W</span>
              {currentDate.week()}
            </div>
          </div>
          {weekDays.map((weekDay) => (
            <WeekDay
              key={weekDay.toISOString()}
              date={weekDay}
              day={weekDay.format("ddd")}
              isCurrent={weekDay.isSame(currentDate, "days")}
            ></WeekDay>
          ))}
        </div>
      </div>
      {appContext.viewMode === "calendar" && (
        <div className="relative border border-t-0 rounded-b-lg bg-white">
          {[-1, ...rows].map((row, index) => (
            <div
              className={`flex ${index === 0 ? "h-8" : "h-12"} ${index === rows.length ? "border-none" : "border-b"
                }`}
              key={row}
            >
              <div className="w-10 text-right lg:w-12 justify-center flex items-center relative">
                {row % 2 === 0 && (
                  <div className="absolute w-full h-full bg-white text-right pr-2 text-sm font-bold text-black/[0.5] -mt-6">
                    {row / 2}:00
                  </div>
                )}
                {Math.abs(row % 2) === 1 && (
                  <div
                    className={`absolute w-full h-full bg-white -mt-6 ${index === 0 ? "bg-white" : ""
                      }`}
                  ></div>
                )}
              </div>
              {weekDays.map((weekDay, colIndex) => {
                const currentHour = Math.round((row / 2) * 100) / 100;
                return (
                  <HourCell
                    key={`r_${index}_${weekDay.day()}`}
                    className={`${getCellStyle(index, colIndex)} ${weekDay.isSame(currentDate, "days")
                        ? " "
                        : "hidden lg:block"
                      }`}
                    onClick={onHourClicked(weekDay, currentHour)}
                  >
                    {eventsInWeek &&
                      (eventsInWeek
                        .get(weekDay.format("YYYY-MM-DD")) || [])
                        .filter((e) =>
                          shouldRenderEvent(e, weekDay, currentHour)
                        )
                        .map((event) => (
                          <EventCard key={event.id} event={event} row={row} />
                        ))}
                    {shouldRenderCurrentTime(
                      currentTime,
                      weekDay,
                      currentHour
                    ) && (
                        <div
                          id="current-time"
                          style={{
                            top: getTop(dayjs().toISOString(), currentHour),
                          }}
                          className="current-time border-b-2 border-red-500 absolute z-10 top-32 w-full"
                        >
                          <div className="absolute rounded-full w-4 h-4 bg-red-500 -top-2 -left-2"></div>
                        </div>
                      )}
                  </HourCell>
                );
              })}
            </div>
          ))}
        </div>
      )}
      {appContext.viewMode === "list" && (
        <div>
          {events
            .filter((e) => isEventForToday(e, currentDate))
            .sort(
              (a, b) => new Date(a.from).getTime() - new Date(b.from).getTime()
            )
            .map((event) => (
              <EventCardSimple key={event.id} event={event} />
            ))}
        </div>
      )}
    </div>
  );
};

export default WeekView;
