import { FunctionComponent } from "react";
import { baseInputFieldStyles } from "../styleUtils";

interface TextFieldProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
{
  
}
 
const TextField: FunctionComponent<TextFieldProps> = ({className, ...props}) => {
  return (<input className={`${baseInputFieldStyles} ${className}`} type="text" {...props} />);
}
 
export default TextField;