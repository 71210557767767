import { FunctionComponent, useRef } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";

interface SlideButtonProps {
  id: string;
  children?: React.ReactNode;
  labelClassName?: string;
  label: string  | React.ReactNode;
  slideClassName?: string;  
  className?: string;
}

const SlideButton: FunctionComponent<SlideButtonProps> = ({ id, children, labelClassName, label, slideClassName, className }) => {
  const ckbRef = useRef<HTMLInputElement>(null);
  const closeForm = () => {
    if (ckbRef.current)
      ckbRef.current.checked = false;
  }
  const ref = useDetectClickOutside({ onTriggered: closeForm });

  return (<div ref={ref} className={`inline-flex relative ${className}`}>
    <label htmlFor={id} className={`${labelClassName}`}>
      {label}
    </label>
    <input ref={ckbRef} type="checkbox" id={id} className="hidden peer" />
    <div className={`absolute top-8 border bg-white rounded-md text-sm hidden peer-checked:block ${slideClassName}`}>
      {children}
    </div>
  </div>);
}

export default SlideButton;